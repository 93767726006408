.ProseMirror-menubar {
  padding: 4px 4px 4px 4px;
}

.emoji-mart-preview {
  height: 48px !important;
}

.emoji-mart-category-label span {
  font-size: 12px;
  font-family: Inter;
}

.emoji-mart {
  box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.14), 0px 0px 1px rgba(0, 0, 0, 0.25);
}

.emoji-mart-search > input {
  font-family: Inter;
}

.emoji-mart-category li {
  cursor: pointer;
}

.emoji-mart-category button {
  cursor: pointer;
}

.emoji-mart-emoji span {
  cursor: pointer !important;
}

.emoji-mart-category-label * {
  cursor: default !important;
}